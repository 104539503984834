<template>
	<div class="About">
		<div class="widthMin company">
			<div class="row p-3 mb-3 bg-white ">
				<div class="col-md-4 pt-5" >
					<img :src="rz_f_13" alt="编组 35" class="img-thumbnail">
				</div>
				<div class="rt col-md-8">
					<h2 class="text-center mb-4 font-weight-bold">杭州伽点科技信息有限公司</h2>
					<div class="com-txt">
						<p class="mt-3 mt-lg-4 ">
							杭州伽点科技信息有限公司，是一家以技术、服务、智能硬件为核心驱动力的，提供综合性拆迁行业综合解决方案的公司。专业立足于拆迁行业，依托集团11年的行业资源、客户资源、大数据资源为基础，通过线上线下的形式为融配资双方提供项目推介服务、资产处置服务以及资产管理保全等综合性服务。公司以拆迁方的资金痛点为基础，建设性的通过“处置服务+管理保全服务”的产品模式，真正帮融配资双方搭建了能够以拆迁物作为抵押物的融资渠道。
						</p>
						<p class="mt-3 mt-lg-4 ">
							公司的使命是，通过项目推介服务帮助急需资金的拆迁客户得到资金赋能；通过资产处置平台相关服务，帮融方客户更好的处置好资产、代收取抵押物的变卖款并自动扣除还款金额以保障资方利益；通过资产管理平台赋能拆迁现场管理，提升管理效率，同时通过物控保全资方的抵押物资产，确保资方利益。从而确保融配资双方在“拆迁物抵押贷款”的利益和商务合作关系的和谐共生。
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="widthMin company">
			<div class="row p-3 pt-5  mb-3 bg-white ">
				<div class="rt col-md-8">
					<h2 class="text-center mb-4 font-weight-bold">河北正和网络科技集团</h2>
					<div class="com-txt">
						<p class="mt-3 mt-lg-4 ">
							集团成立于2009年2月，是一家专注于“互联网+再生资源”的B2B电子商务运营商，为企业单位和回收商人搭建信息资讯、品牌推广以及多方位服务的综合性公司。旗下有回收商网、爱玖库、和拍网三大互联网平台。
						</p>
						<p class=" mt-3 mt-lg-4">
							公司自成立以来，一直秉承“为客户创造价值，让回收商人富起来”的宗旨，以坚实的互联网开发技术为基础，以极致愉悦的服务体验为保障，旨在赋能再生资源产业，改变营销、推广及经营方式，为回收企业提供互联网基础设施、营销及交易平台，借助互联网的力量让供求双方产生互动，从而达到合作共赢。
						</p>
						<p class="mt-3 mt-lg-4 ">
							公司先后荣获“高新技术企业”、“国家科技型中小企业”、“河北省科技型中小企业”、“再生资源行业创新发展 互联网平台创新企业”、“移动互联网领导力奖”等二十余项荣誉称号。拥有计算机软件著作权22项，申请发明专利2项。
						</p>
					</div>
				</div>
				<div class="col-md-4 pt-5" >
					<img :src="rz_f_13" alt="编组 35" class="img-thumbnail">
				</div>
			</div>
		</div>
		<!-- 公司伙伴 -->
		<div class="partner ba-parImg " :style="{'background-image': 'url('+rz_a_4+')'}">
			<div class="widthMin content text-center pb-4">
				<h2 class="mb-2 pt-md-5">集团合作伙伴及相关项目</h2>
				<h4 class="mb-4">The Others and Case</h4>
				<ul class="row pt-md-3 ">
					
					<li class="col-md-3 mb-3 col-lg-2 "><div class="con-item">Tencent腾讯</div></li>
					<li class="col-md-3 mb-3 col-lg-2"><div class="con-item">Tencent腾讯</div></li>
					<li class="col-md-3 mb-3 col-lg-2"><div class="con-item">Tencent腾讯</div></li>
					<li class="col-md-3 mb-3 col-lg-2"><div class="con-item">Tencent腾讯</div></li>
					<li class="col-md-3 mb-3 col-lg-2"><div class="con-item">Tencent腾讯</div></li>
					<li class="col-md-3 mb-3 col-lg-2"><div class="con-item">Tencent腾讯</div></li>
					<li class="col-md-3 mb-3 col-lg-2"><div class="con-item">Tencent腾讯</div></li>
					<li class="col-md-3 mb-3 col-lg-2"><div class="con-item">Tencent腾讯</div></li>
					<li class="col-md-3 mb-3 col-lg-2"><div class="con-item">Tencent腾讯</div></li>
					<li class="col-md-3 mb-3 col-lg-2"><div class="con-item">Tencent腾讯</div></li>
					<li class="col-md-3 mb-3 col-lg-2"><div class="con-item">Tencent腾讯</div></li>
					<li class="col-md-3 mb-3 col-lg-2"><div class="con-item">Tencent腾讯</div></li>
					<li class="col-md-3 mb-3 col-lg-2"><div class="con-item">Tencent腾讯</div></li>
					<li class="col-md-3 mb-3 col-lg-2"><div class="con-item">Tencent腾讯</div></li>
					<li class="col-md-3 mb-3 col-lg-2"><div class="con-item">Tencent腾讯</div></li>
					<li class="col-md-3 mb-3 col-lg-2"><div class="con-item">Tencent腾讯</div></li>
					<li class="col-md-3 mb-3 col-lg-2"><div class="con-item">Tencent腾讯</div></li>
					<li class="col-md-3 mb-3 col-lg-2"><div class="con-item">Tencent腾讯</div></li>
					<li class="col-md-3 mb-3 col-lg-2"><div class="con-item">Tencent腾讯</div></li>
					<li class="col-md-3 mb-3 col-lg-2"><div class="con-item">Tencent腾讯</div></li>
					<li class="col-md-3 mb-3 col-lg-2"><div class="con-item">Tencent腾讯</div></li>
					<li class="col-md-3 mb-3 col-lg-2"><div class="con-item">Tencent腾讯</div></li>
					<li class="col-md-3 mb-3 col-lg-2"><div class="con-item">Tencent腾讯</div></li>
					<li class="col-md-3 mb-3 col-lg-2"><div class="con-item">Tencent腾讯</div></li>
				</ul>
			</div>
		</div>
		<!-- 媒体报道 -->
		<div class="mediaBox widthMin">
			<h2 class="text-center mb-2 pt-md-5">媒体报道</h2>
			<h4 class="text-center mb-5 ">Media Coverage</h4>
			<ul class="row mb-2">
				<li class="col-md-6 mb-3">
					<div class="row no-gutters border rounded overflow-hidden flex-md-row shadow-sm h-md-250 ">
						<div class="col-md-3 p-3" >
							 <img src="../../assets/images/web_h5/a_1.png" class="card-img" alt="...">
						</div>
						
						 <div class=" col-md-9 p-3 pl-md-0">
						    <div class="card-body p-0">
						  		<p>谁的人生没有起起落落？很荣幸成为他们的加油站谁的人生没有起起落落？很荣幸成为他们的加油站</p>
						    </div>
							<div class="mt-2 d-flex justify-content-between">
								<span class="cBlue">来源·搜狐</span>
								<span class="cGrey">查看详情>> </span>
							</div>
						 </div>
					</div>
				</li>
				<li class="col-md-6 mb-3">
					<div class="row no-gutters border rounded overflow-hidden flex-md-row shadow-sm h-md-250 ">
						<div class="col-md-3 p-3" >
							 <img src="../../assets/images/web_h5/a_1.png" class="card-img" alt="...">
						</div>
						
						 <div class=" col-md-9 p-3 pl-md-0">
						    <div class="card-body p-0">
						  		<p>谁的人生没有起起落落？很荣幸成为他们的加油站谁的人生没有起起落落？很荣幸成为他们的加油站</p>
						    </div>
							<div class="mt-2 d-flex justify-content-between">
								<span class="cBlue">来源·搜狐</span>
								<span class="cGrey">查看详情>> </span>
							</div>
						 </div>
					</div>
				</li>
				<li class="col-md-6 mb-3">
					<div class="row no-gutters border rounded overflow-hidden flex-md-row shadow-sm h-md-250 ">
						<div class="col-md-3 p-3" >
							 <img src="../../assets/images/web_h5/a_1.png" class="card-img" alt="...">
						</div>
						
						 <div class=" col-md-9 p-3 pl-md-0">
						    <div class="card-body p-0">
						  		<p>谁的人生没有起起落落？很荣幸成为他们的加油站谁的人生没有起起落落？很荣幸成为他们的加油站</p>
						    </div>
							<div class="mt-2 d-flex justify-content-between">
								<span class="cBlue">来源·搜狐</span>
								<span class="cGrey">查看详情>> </span>
							</div>
						 </div>
					</div>
				</li>
				<li class="col-md-6 mb-3">
					<div class="row no-gutters border rounded overflow-hidden flex-md-row shadow-sm h-md-250 ">
						<div class="col-md-3 p-3" >
							 <img src="../../assets/images/web_h5/a_1.png" class="card-img" alt="...">
						</div>
						
						 <div class=" col-md-9 p-3 pl-md-0">
						    <div class="card-body p-0">
						  		<p>谁的人生没有起起落落？很荣幸成为他们的加油站谁的人生没有起起落落？很荣幸成为他们的加油站</p>
						    </div>
							<div class="mt-2 d-flex justify-content-between">
								<span class="cBlue">来源·搜狐</span>
								<span class="cGrey">查看详情>> </span>
							</div>
						 </div>
					</div>
				</li>
				<li class="col-md-6 mb-3">
					<div class="row no-gutters border rounded overflow-hidden flex-md-row shadow-sm h-md-250 ">
						<div class="col-md-3 p-3" >
							 <img src="../../assets/images/web_h5/a_1.png" class="card-img" alt="...">
						</div>
						
						 <div class=" col-md-9 p-3 pl-md-0">
						    <div class="card-body p-0">
						  		<p>谁的人生没有起起落落？很荣幸成为他们的加油站谁的人生没有起起落落？很荣幸成为他们的加油站</p>
						    </div>
							<div class="mt-2 d-flex justify-content-between">
								<span class="cBlue">来源·搜狐</span>
								<span class="cGrey">查看详情>> </span>
							</div>
						 </div>
					</div>
				</li>
				<li class="col-md-6 mb-3">
					<div class="row no-gutters border rounded overflow-hidden flex-md-rowshadow-sm h-md-250 ">
						<div class="col-md-3 p-3" >
							 <img src="../../assets/images/web_h5/a_1.png" class="card-img" alt="...">
						</div>
						
						 <div class=" col-md-9 p-3 pl-md-0">
						    <div class="card-body p-0">
						  		<p>谁的人生没有起起落落？很荣幸成为他们的加油站谁的人生没有起起落落？很荣幸成为他们的加油站</p>
						    </div>
							<div class="mt-2 d-flex justify-content-between">
								<span class="cBlue">来源·搜狐</span>
								<span class="cGrey">查看详情>> </span>
							</div>
						 </div>
					</div>
				</li>
				
				<li class="col-md-6 mb-3">
					<div class="row no-gutters border rounded overflow-hidden flex-md-row shadow-sm h-md-250 ">
						<div class="col-md-3 p-3" >
							 <img src="../../assets/images/web_h5/a_1.png" class="card-img" alt="...">
						</div>
						
						 <div class=" col-md-9 p-3 pl-md-0">
						    <div class="card-body p-0">
						  		<p>谁的人生没有起起落落？很荣幸成为他们的加油站谁的人生没有起起落落？很荣幸成为他们的加油站</p>
						    </div>
							<div class="mt-2 d-flex justify-content-between">
								<span class="cBlue">来源·搜狐</span>
								<span class="cGrey">查看详情>> </span>
							</div>
						 </div>
					</div>
				</li>
				<li class="col-md-6 mb-3">
					<div class="row no-gutters border rounded overflow-hidden flex-md-row shadow-sm h-md-250 ">
						<div class="col-md-3 p-3" >
							 <img src="../../assets/images/web_h5/a_1.png" class="card-img" alt="...">
						</div>
						
						 <div class=" col-md-9 p-3 pl-md-0">
						    <div class="card-body p-0">
						  		<p>谁的人生没有起起落落？很荣幸成为他们的加油站谁的人生没有起起落落？很荣幸成为他们的加油站</p>
						    </div>
							<div class="mt-2 d-flex justify-content-between">
								<span class="cBlue">来源·搜狐</span>
								<span class="cGrey">查看详情>> </span>
							</div>
						 </div>
					</div>
				</li>
			</ul>
		</div>
		
		<!-- welcom -->
		<div class="welcom widthMin">
			<h2 class="text-center mb-2 pt-md-5">我们在这里等你到来</h2>
			<h4 class="text-center mb-5 ">We'll wait for you here</h4>
			<ul class="row mb-2 content">
				<li class="col-md-12">
					<div class="row">
						<img src="../../assets/images/web_h5/a_2.png" class="card-img-top col-md-6 p-0" alt="...">
						<div class="card-body col-md-6 p-5">
						 	<h2 class="mb-4 mt-2 font-weight-bold card-title">河北正和网络科技集团</h2>
						 	<p>
								地址：石家庄高新区长江大道168号天山银河广场C座6F
							</p>
							<p>
								E-MAIL：FUWU@HUISHOUSHANG.COM
							</p>
							<p>
								电话：400-707-9877
							</p>
						</div>
					</div>
				</li>
				<li class="col-md-12 ">
					<div class="row">
						 <div class="card-body col-md-6 p-5">
						  	<h2 class="mb-4 mt-4 font-weight-bold card-title ">杭州伽点科技信息有限公司</h2>
						  	<p>
						 		地址：石家庄中山路嘉和广场2号写字楼17F
						 	</p>
						 	<p>
								E-MAIL：FUWU@HUISHOUSHANG.COM
						 	</p>
						 	<p>
						 		电话：13383611852
						 	</p>
						  	
						 </div>
						 <img :src="rz_a_3" class="card-img-top col-md-6 p-0 mg-t" alt="...">
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				rz_f_13:this.imgBasUrl2+'rz_f_13.png',
				rz_a_3:this.imgBasUrl2+'rz_a_3.png',
				rz_a_4:this.imgBasUrl2+'rz_a_4.png',
			}
		},
		methods: {
		},
	};
</script>

<style>
	@import url('../../assets/css/web_h5/about.css');
</style>